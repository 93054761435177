<template>
    <div>
        <div class="mb-5 p-3 alert-danger text-sm rounded-sm" v-if="!zipcode_available">
            {{ $t('notification.zipcodeunavailable') }}
        </div>

        <Form ref="form" :validation-schema="validationRules" v-slot="{ errors }">
            <div class="sm:grid sm:grid-cols-2 gap-3">
                <div class="sm:col-span-1 mb-3">
                    <label for="zipcode" class="block text-xs" :class="{'text-red-500': errors.zipcode}">{{ $t('fields.zipcode') }} *</label>
                    <div class="mt-1">
                        <Field @change="addressZipcodeUpdated()" v-model="form.zipcode" type="text" name="zipcode" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.zipcode}" />
                    </div>
                </div>
                <div class="sm:col-span-1 mb-3">
                    <label for="housenumber" class="block text-xs" :class="{'text-red-500': errors.housenumber}">{{ $t('fields.housenumber_addition') }} *</label>
                    <div class="mt-1">
                        <Field @change="addressSet()" v-model="form.housenumber" type="text" name="housenumber" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.housenumber}" />
                    </div>
                </div>
            </div>

            <!-- streetname -->
            <div class="mb-3">
                <label for="streetname" class="block text-xs" :class="{'text-red-500': errors.streetname}">{{ $t('fields.streetname') }} *</label>
                <div class="mt-1">
                    <Field type="text" name="streetname" v-model="form.streetname" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.streetname}" />
                </div>
            </div>

            <!-- city -->
            <div class="">
                <label for="city" class="block text-xs" :class="{'text-red-500': errors.city}">{{ $t('fields.city') }} *</label>
                <div class="mt-1">
                    <Field type="text" name="city" v-model="form.city" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.city}" />
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import { Field, Form } from 'vee-validate';
import * as yup from 'yup';

// libs
import * as _state from '../lib/state';
import * as _address from '../lib/address';

export default {
    name: 'FormAddress',
    props: ['address'],
    components: {
        Field,
        Form,
    },
    data () {
        return {
            errors: {},
            zipcode_available: true,
            validationRules: {
                zipcode: yup.string().max(7).required(),
                housenumber: yup.string().max(10).required(),
                streetname: yup.string().max(255).required(),
                city: yup.string().max(255).required(),
            },
            form: {
                zipcode: '',
                housenumber: '',
                streetname: '',
                city: '',
            },
        }
    },

    async mounted() {
        // set default date
        this.form.zipcode = this.address ? this.address.zipcode : '';
        this.form.housenumber = this.address ? this.address.housenumber : '';
        this.form.streetname = this.address ? this.address.streetname : '';
        this.form.city = this.address ? this.address.city : '';
    },

    methods: {
        async addressSet() {
            if (this.form.zipcode && this.form.housenumber) {

                let address = await _address.retrieve(this.form.zipcode, this.form.housenumber.toString())

                this.form.streetname = address.streetname;
                this.form.city = address.city;
            }
        },

        async addressZipcodeUpdated() {
            this.zipcode_available = await _address.isZipcodeAvailable(this.form.zipcode)

            if (this.zipcode_available == true) {
                this.addressSet();
            }
        },

        async validate() {
            if (this.zipcode_available === false) {
                return false;
            }
            let schema = yup.object().shape(this.validationRules);
            let schemaValid = await schema.isValid(this.form);

            return schemaValid;
        },

        validateAndEmit() {
            this.$emit('changed', this.form);

            return this.validate();
        },
    },

    computed: {
        config () {
            return _state.get('config/getConfig');
        },

        settings_dateselection_menu() {
            return this.config.settings.dateselection_menu;
        },
    },

    watch: {
        form: {
            handler(){
                this.validateAndEmit()
            },
            deep: true
        }
    }
}
</script>
