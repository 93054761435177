<template>
    <div v-if="show" class="fixed w-full z-10 inset-0 overflow-y-auto ccss--modal-items-not-available">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div
                class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
                role="dialog" aria-modal="true" aria-labelledby="modal-headline"
            >
                <div class="">
                    <div class="modal-close">
                        <button @click="close()" class="modal-close" aria-label="Close">
                            <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>

                    <div class="pt-8">
                        <h2 class="text-center mb-2 text-xl">{{ $t('items.not.available.title') }}</h2>
                        <h3 class="text-center">{{ $t('items.not.available.description') }}</h3>
                        <div class="my-6 mx-6 overflox-y-scroll max-h-30">
                            <div v-for="item in this.items" :key="item.item_id" class="flex justify-between pb-4 border-b border-gray my-3">
                                <!-- <div class="w-1/2"> -->
                                <div class="">
                                    <div class="text-main text-xs font-bold">
                                        {{ item.description }}
                                    </div>
                                </div>
                                <!-- <div class="w-1/2 flex justify-end">
                                    <div class="text-main px-3 text-xs font-bold">
                                        <span class="italic text-sm text-red-800 pl-3">(removed)</span>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="flex justify-between mb-5">
                            <button @click="$router.go(-1)" class="btn btn-primary inline-flex w-full mx-5 my-2 justify-center items-center px-6 py-3 text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                {{ $t('order.button.back')}}
                            </button>
                            <button @click="close()" class="btn btn-primary-outline inline-flex w-full mx-5 my-2 justify-center items-center px-6 py-3 text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                {{ $t('button.continue')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'itemsnotavailable',
    data() {
        return {
            show: false,
            items: []
        }
    },
    methods: {
        open(unavailable_items) {
            this.show = true;
            this.items = unavailable_items;
        },

        close() {
            this.show = false;
        }
    },

}
</script>
