import * as _location from '../lib/location';
import * as _transaction from '../lib/transaction';
import * as yup from 'yup';

export default {
    methods: {
        getValidationRules() {
            const method = _transaction.getMethod();

            // get validation scheme
            if (method === 'quickorder') {
                return this.getValidationSchemaQuickorder();
            } else if (method === 'delivery') {
                return this.getValidationSchemaDelivery();
            } else if (method === 'elockers') {
                return this.getValidationSchemaElockers();
            } else if (method === 'takeaway') {
                return this.getValidationSchemaTakeaway();
            } else if (method === 'tableservice') {
                return this.getValidationSchemaTableservice();
            } else if (method === 'reservation') {
                return this.getValidationSchemaReservation();
            }

            return yup.object().shape({});
        },

        getValidationSchemaDelivery() {
            let validations = {
                account_id: yup.string(),

                customer_firstname: yup.string().required().max(255),
                customer_lastname: yup.string().required().max(255),
                customer_email: yup.string().required().email().max(255),

                // todo: required if phonenumber is entered
                customer_phone_number_countrycode: yup.string().required().max(10),
                customer_phone_number: yup.string().required().max(10),

                customer_notes: yup.string(),
            };

            const location = _location.get();

            if (location.delivery.type === 'tables') {
                validations.table_id = yup.string().max(255).required();
            }

            return yup.object().shape(validations);
        },

        getValidationSchemaTakeaway() {
            const location = _location.get();

            let validations = {
                // paymenttype_id: yup.number().required(),
                account_id: yup.string(),
                customer_email: yup.string().required().email().max(255),
                customer_notes: yup.string(),
            };

            if (location.takeaway.emailonly == false) {
                validations.customer_firstname = yup.string().required().max(255);
                validations.customer_lastname = yup.string().required().max(255);

                validations.customer_phone_number_countrycode = yup.string().required().max(10);
                validations.customer_phone_number = yup.string().required().max(10);
            }

            return yup.object().shape(validations);
        },

        getValidationSchemaQuickorder() {
            const location = _location.get();

            let validations = {
                // paymenttype_id: yup.number().required(),
                customer_email: yup.string().required().email().max(255),
                customer_phone_number_countrycode: yup.string().max(10),
                customer_phone_number: yup.string().max(10),
            };

            // phonenumber
            if (location.quickorder.phone_number_required == true) {
                validations.customer_phone_number_countrycode = yup.string().max(10).required();
                validations.customer_phone_number = yup.string().max(10).required();
            }

            // email
            if (location.quickorder.email_required == false) {
                validations.customer_email = yup.string().required().email().max(255);
            }

            return yup.object().shape(validations);
        },

        getValidationSchemaReservation() {
            let validations = {
                customer_email: yup.string().required().email().max(255),
                customer_phone_number_countrycode:  yup.string().max(10).required(),
                customer_phone_number: yup.string().max(10).required(),
            };

            return yup.object().shape(validations);
        },

        getValidationSchemaElockers() {
            let validations = {
                customer_email: yup.string().required().email().max(255),
                customer_phone_number_countrycode: yup.string().max(10),
                customer_phone_number: yup.string().max(10),
                termsandconditions: yup.bool().required(),
                privacypolicy: yup.bool().required(),
            };

            return yup.object().shape(validations);
        },

        getValidationSchemaTableservice() {
            const location = _location.get();

            let validations = {
                // paymenttype_id: yup.number().required(),
                customer_email: yup.string().required().email().max(255),
                customer_phone_number_countrycode: yup.string().max(10),
                customer_phone_number: yup.string().max(10),
            };

            // phonenumber
            if (location.quickorder.phone_number_required == true) {
                validations.customer_phone_number_countrycode = yup.string().max(10).required();
                validations.customer_phone_number = yup.string().max(10).required();
            }

            // email
            if (location.quickorder.email_required == false) {
                validations.customer_email = yup.string().required().email().max(255);
            }

            return yup.object().shape(validations);
        },
    },
};
