<template>
    <div>
        <div v-if="settings_dateselection_menu == true" class="mt-5 mb-3">
            <label for="date" class="block text-xs" :class="{ 'text-red-500': errors.date }">{{ $t('fields.date') }} *</label>
            <div class="mt-1">
                <input
                    type="text"
                    disabled
                    :value="form.date"
                    class="bg-gray-100 py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                />
            </div>
        </div>

        <!-- delivery date and time -->
        <!-- view: inputfield -->
        <div v-if="timeslot_view == 'inputfield'" class="sm:grid sm:grid-cols-2 gap-4 mt-5">
            <div class="sm:col-span-1 mt-4" v-if="settings_dateselection_menu == false">
                <label for="date" class="block text-xs" :class="{ 'text-red-500': errors.date }">{{ $t('fields.date') }} *</label>
                <div class="mt-1 mb-3 sm:mb-0">
                    <v-date-picker
                        @dayclick="dateUpdated()"
                        :disabled-dates="unavailable_dates"
                        :min-date="new Date()"
                        :max-date="maximum_date"
                        v-model="form.date"
                        :attributes="datepicker_attributes"
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                readonly
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                        </template>
                    </v-date-picker>
                </div>
            </div>

            <div class="sm:col-span-1 mt-4 sm:mt-0">
                <label for="time" class="block text-xs" :class="{ 'text-red-500': errors.time }">{{ $t('fields.time') }} *</label>
                <div class="mt-1 relative" v-if="timeslots.length > 0">
                    <select
                        name="time"
                        v-model="form.time"
                        as="select"
                        @change="emit()"
                        class="h-10 py-1 px-2 form-select w-full shadow-sm border rounded-sm"
                        :class="{ error: errors.time }"
                    >
                        <option></option>
                        <option :value="timeslot.slot" v-for="(timeslot, index) in timeslots" :key="index" :disabled="!timeslot.available">
                            {{ timeslot.slot }} <span v-if="!timeslot.available">({{ $t('order.timeslotunavailable') }})</span>
                        </option>
                    </select>
                </div>
                <div v-else class="mt-1 rounded-sm p-4 mb-5 alert-warning text-center">
                    {{ $t('order.notimeslots') }}
                </div>
            </div>
        </div>

        <!-- view: calendar -->
        <div v-else-if="timeslot_view == 'calendar'" class="sm:grid sm:grid-cols-2 gap-4 mt-5 mb-3">
            <div class="sm:col-span-1 mt-4 sm:mt-0" v-if="settings_dateselection_menu == false">
                <label for="date" class="block text-xs" :class="{ 'text-red-500': errors.date }">{{ $t('fields.date') }} *</label>
                <div class="mt-1">
                    <v-date-picker
                        @dayclick="dateUpdated()"
                        :disabled-dates="unavailable_dates"
                        :min-date="new Date()"
                        :max-date="maximum_date"
                        v-model="form.date"
                        :attributes="datepicker_attributes"
                    />
                </div>
            </div>
            <div class="sm:col-span-1 mt-4 sm:mt-0">
                <input type="hidden" v-model="form.time" name="time" />
                <label for="time" class="block text-xs" :class="{ 'text-red-500': errors.time }">{{ $t('fields.time') }} *</label>
                <div v-if="timeslots.length == 0" class="mt-1 rounded-sm p-4 mb-5 alert-warning text-center">
                    {{ $t('order.notimeslots') }}
                </div>
                <div class="mt-1 grid grid-cols-2 gap-2">
                    <div
                        v-for="(timeslot, index) in timeslots"
                        :key="index"
                        @click="selectTimeslot(timeslot)"
                        class="btn btn-small checkout-option"
                        :class="{ 'line-through text-muted': !timeslot.available, active: timeslot.slot == form.time }"
                    >
                        {{ timeslot.slot }}
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="timeslots.length == 0" class="mt-5 mb-3">
            <div class="mt-1 rounded-sm p-4 mb-5 alert-danger text-center">
                {{ $t('order.notimeslots') }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import * as yup from 'yup';

// libs
import * as _api from '../lib/api';
import * as _notification from '../lib/notification';
import * as _state from '../lib/state';

export default {
    name: 'FormDateTimeSelection',
    props: ['method'],
    // props: ['date', 'method'],
    data() {
        return {
            errors: {},
            form: {
                date: '',
                time: '',
            },

            timeslots: [],

            unavailable_dates: [],
            maximum_date: false,
            timeslot_view: '',

            datepicker_attributes: [
                {
                    excludeDates: null,
                },
            ],
        };
    },

    async mounted() {
        // const metaCacheControl = document.createElement('meta');
        // metaCacheControl.httpEquiv = 'Cache-Control';
        // metaCacheControl.content = 'no-store';
        // document.head.appendChild(metaCacheControl);

        if (!this.method) {
            _notification.set(this.$t('notification.datetime-selection.methodnotset'), 'error');
            return;
        }

        // let now = Date.now();
        // console.log('now',now);
        // console.log(moment(now).format('YYYY-MM-DD'))
        // set default date
        this.form.date = moment().format('YYYY-MM-DD');
        // this.form.date = this.date;

        await this.getTimeslots();
    },

    methods: {
        // todo: refactor validation
        async validate() {
            this.errors = {};

            if ((await this.validateDate()) === false) {
                this.errors.date = true;
            }

            if ((await this.validateTime()) === false) {
                this.errors.time = true;
            }

            return !this.errors.date && !this.errors.time;
        },

        async validateDate() {
            let schema = yup.object().shape({
                date: yup.date().required(),
            });

            return await schema.isValid({ date: this.form.date });
        },

        async validateTime() {
            let schema = yup.object().shape({
                time: yup.string().required(),
            });
            return await schema.isValid({ time: this.form.time });
        },

        async getTimeslots() {
            if (['tableservice', 'quickorder'].includes(this.method) || !this.form.date) {
                return;
            }
            console.log(this.form.date);
            const response = await _api.post('timeslots', {
                date: moment(this.form.date).format('YYYY-MM-DD'),
                function: this.method,
                area_id: null,
            });

            if (!response) {
                _notification.set(this.$t('notification.datetime-selection.notimeslots'), 'error');
                return;
            }

            this.timeslot_view = response.attributes.view ? response.attributes.view : 'inputfield';
            this.timeslots = response.attributes.timeslots;

            this.unavailable_dates = response.attributes.unavailable_dates;

            this.maximum_date = response.attributes.maximum_date;

            if (this.timeslots.length == 1) {
                if (this.timeslots[0].available == true) {
                    this.form.time = this.timeslots[0].slot;
                }
            }
        },

        selectTimeslot(timeslot) {
            if (!timeslot.available) {
                _notification.set(this.$t('notification.datetime-selection.timeslotunavailable'), 'error');
                return;
            }
            this.form.time = timeslot.slot;
        },

        async dateUpdated() {
            this.form.time = '';

            this.emit();

            await this.getTimeslots();
        },

        emit() {
            this.validate();
            this.$emit('changed', this.form);
        },
    },

    computed: {
        config() {
            return _state.get('config/getConfig');
        },

        // todo: move to prop of this component
        settings_dateselection_menu() {
            return this.config.settings.dateselection_menu;
        },
    },

    watch: {
        'form.time': function () {
            this.emit();
        },
    },
};
</script>
